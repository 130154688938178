<template>
  <div class="user-modal">
    <!-- overlay -->
    <div id="overlay" class="overlay" @click="toggleSideBar('close')"></div>
    <!-- sidebar -->
    <div id="user-sidebar" class="user-sidebar">
      <div class="close-btn" @click="toggleSideBar('close')">X</div>
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/website-use/logo_word.png" alt="" />
      </div>
      <UserInfoCp></UserInfoCp>
      <ul class="sidebar-list">
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '關聯帳號' }"
          @click="go('關聯帳號')"
        >
          <img src="@/assets/icon/comment-user.png" alt="" />關聯帳號
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '收件資訊' }"
          @click="go('收件資訊')"
        >
          <img src="@/assets/icon/envelope.png" alt="" />收件資訊
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '賣場列表' }"
          @click="go('賣場列表')"
        >
          <img src="@/assets/icon/home.png" alt="" />賣場列表
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '付費方案' }"
          @click="go('付費方案')"
        >
          <img src="@/assets/icon/dollar.png" alt="" />付費方案
        </li>
      </ul>
      <p class="sidebar-copy-right">©2022~2023優碼興科技股份有限公司</p>
    </div>
    <!-- sidebar toggle btn -->
    <div
      id="show-sidebar-btn"
      class="show-sidebar-btn hide-sidebar-btn d-flex align-items-center"
      @click="toggleSideBar('show')"
    >
      <span class="fw-bolder">{{ currentPage }}</span>
      <img src="@/assets/icon/angle-double-small-right.png" alt="" />
    </div>
    <!-- sidebar area -->
    <div id="sidebar-area" class="sidebar-area"></div>
    <!-- router view -->
    <div id="user-content" class="user-content">
      <div style="margin-top: 40px"></div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// components
import UserInfoCp from '@/components/sidebar/UserInfo_cp'

export default {
  components: { UserInfoCp },
  data() {
    return {
      currentPage: ''
    }
  },
  created() {
    this.checkCurrentPage()
  },
  mounted() {
    this.toggleSideBar('close')
  },
  watch: {
    $route(to, from) {
      if (to.name === 'Login' || to.name === 'CustomerLogin') return
      this.checkCurrentPage()
    },
  },
  methods: {
    // sidebar 切換
    toggleSideBar(status) {
      if (status === 'close') {
        $('#user-sidebar').addClass('close-sidebar')
        $('#show-sidebar-btn').removeClass('hide-sidebar-btn')
        $('#sidebar-area').addClass('close-sidebar-area')
        $('#user-content').addClass('content-width100')
        $('#overlay').removeClass('overlay')
      } else {
        $('#user-sidebar').removeClass('close-sidebar')
        $('#show-sidebar-btn').addClass('hide-sidebar-btn')
        $('#sidebar-area').removeClass('close-sidebar-area')
        $('#user-content').removeClass('content-width100')
        $('#overlay').addClass('overlay')
      }
    },
    // 前往
    go(where) {
      switch (where) {
        case "關聯帳號":
          this.$router.push({name: 'S_LinkAccounts'})
          break;
        case "收件資訊":
          this.$router.push({name: 'S_ReceiptInfos'})
          break;
        case "賣場列表":
          this.$router.push({name: 'StoreLists'})
          break;
        case "付費方案":
          this.$router.push({name: 'PaymentRecord'})
          break;
      }
      this.toggleSideBar('close')
    },
    // 檢查目前在哪一頁
    checkCurrentPage() {
      this.currentPage = ''
      switch (this.$route.name) {
        case "S_LinkAccounts":
          this.currentPage = '關聯帳號'
          break;
        case "S_ReceiptInfos":
          this.currentPage = '收件資訊'
          break;
        case "StoreLists":
          this.currentPage = '賣場列表'
          break;
        case "PaymentRecord":
          this.currentPage = '付費方案'
          break;
        case "PayPlan":
          this.currentPage = '付費方案'
          break;
        case "MoneyTransfer":
          this.currentPage = '付費方案'
          break;
      }
    },
  }
}
</script>